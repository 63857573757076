import { Link } from 'gatsby';
import React from 'react';
import Layout from '../../components/layout/layout';

import { ImagesMercedesEQE } from '../../Images';

const MercedesEQE: React.FC = () => {
    return (
        <Layout
            title="Mercedes EQE SUV - WrapTown - profesjonalne oklejanie
        samochodów"
            desc="Ochrona lakieru full body Mercedes EQE SUV, sprawdź sam jak wygląda nasza oferta oklejania aut."
            url="https://wraptown.pl/realizacje/mercedes-eqe-suv"
            type="article"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> /{' '}
                        <Link to="/realizacje">Realizacje</Link> / Mercedes EQE SUV
                    </p>
                </div>
                <div className="text-center">
                    <h3>Ochrona lakieru</h3>
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Mercedes EQE SUV - WrapTown
                    </h1>

                    <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
                        {ImagesMercedesEQE.map((image) => {
                            return (
                                <div>
                                    <img
                                        key={image.id}
                                        src={image.href}
                                        alt={image.alt}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default MercedesEQE;
